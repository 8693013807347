<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="6">
        <b-card header-tag="header">
          <div slot="header"><i class="icon-settings"></i> Bot Settings</div>
          <b-form-group>
            <h4 class="smalltitle">Nickname</h4>
            <p>Set the bot's display name in the server.</p>
            <b-input-group>
              <b-form-input
                type="text"
                pattern=".{1,32}"
                maxlength="32"
                placeholder="Carl-bot"
                class="col-12"
                v-model="nickname"
                @keyup.enter.native="handlenickname"
              ></b-form-input>
              <!-- Attach Right button -->
              <b-input-group-append>
                <b-button v-on:click="handlenickname" variant="primary"
                  >Apply</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group>
            <h4 class="smalltitle">Custom Prefix</h4>
            <p>Set one or more prefix</p>
            <b-input-group class="pb-1">
              <b-form-input
                type="text"
                pattern=".{1,10}"
                maxlength="10"
                placeholder="Enter a prefix"
                class="col-12"
                v-model="newPrefix"
                @keyup.enter.native="addPrefix"
              ></b-form-input>
              <!-- Attach Right button -->
              <b-input-group-append>
                <b-button v-on:click="addPrefix" variant="primary"
                  >Add</b-button
                >
              </b-input-group-append>
            </b-input-group>
            <b-list-group>
              <b-list-group-item
                class="todo-item"
                v-for="(prefix, index) in prefixes"
                :key="prefix"
              >
                {{
                  prefix.length > 15 ? prefix.substring(0, 13) + "..." : prefix
                }}
                <i
                  class="remove-item icon-trash float-right"
                  @click="removePrefix(index)"
                  style="cursor: pointer"
                ></i>
              </b-list-group-item>
            </b-list-group>
          </b-form-group>
          <b-form-group v-if="is_vip != null">
            <h4 class="smalltitle">Carlbot Premium</h4>
           <div v-if="is_vip">
           <p v-if="expiryDate && remainingTime >= 0">
           {{ days > 1 ? 'Premium expires in ' + days + ' days.' : 'Premium expires in ' + days + ' day.' }}
          </p>
         <p v-else>
           Active. Enjoy!
         </p>
          </div>
            <div v-else>
              <p v-if="is_piggy === true">Piggy Active. Enjoy!</p>
              <p v-else>
                Not active, get it on
                <a href="https://www.patreon.com/carlbot">Patreon</a>
              </p>
            </div>
          </b-form-group>
          <div v-else class="sk-circle-fade">
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
            <div class="sk-grid-cube"></div>
          </div>
        </b-card>
      </b-col>
      <b-col sm="12" md="6">
        <b-card header-tag="header">
          <div slot="header"><i class="icon-list"></i> Last actions</div>
          <h4 class="smalltitle">Recent actions</h4>
          <hr />
          <v-client-table
            :columns="columns"
            :data="data"
            :options="options"
            :theme="theme"
            id="dataTable"
            style="width: 100%"
          ></v-client-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import { ClientTable, Event } from "vue-tables-2";

Vue.use(ClientTable);
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(VueNotifications, options);

export default {
  name: "dashboard",
  components: {
    Callout,
    cSwitch,
  },
  data: function () {
    return {
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715",
      },
      columns: ["executed", "username", "action"],
      data: [],
      vip_servers: [],
      expiryDate: [],
      remainingTime: 0,
      options: {
        filterByColumn: true,
        columnsDisplay: {
          executed: "not_mobile",
        },
        texts: {
          filter: "Filter",
          filterBy: "Filter by {column}",
        },
        sortable: ["executed", "username", "action"],
        filterable: ["user_id", "username", "action"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort",
        },
        pagination: {
          chunk: 5,
          edge: true,
          nav: "scroll",
        },
        childRow: false,
        highlightMatches: true,
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",

      bot_channel: null,
      nickname: "",
      newPrefix: "",
      is_vip: null,
      is_piggy: false,
      prefixes: ["!", "?"],
      modonly: false,
    };
  },
  notifications: {
    showSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Nickname changed",
    },
    showInfoMsg: {
      type: VueNotifications.types.info,
      title: "No changes",
      message: "You already have that prefix.",
    },
    showWarnMsg: {
      type: VueNotifications.types.success,
      title: "Wow, man",
      message: "That's the kind of warning",
    },
    showSuccessPrefix: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Prefix added",
    },
    showSuccessPrefixRemoval: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Prefix removed",
    },
    showErrorMsgPrefix: {
      type: VueNotifications.types.warn,
      title: "Uh-oh",
      message: "You need to enter a prefix!",
    },
    showErrorMsgNick: {
      type: VueNotifications.types.warn,
      title: "Uh-oh",
      message: "You need to enter a nickname!",
    },
    showErrorMsgDuplicate: {
      type: VueNotifications.types.warn,
      title: "Uh-oh",
      message: "You already have that prefix!",
    },
    showErrorMsgTooManyPrefixes: {
      type: VueNotifications.types.error,
      title: "Uh-oh",
      message: "You already have the maximum allowed number of prefixes!",
    },
  },
  computed: {
    days() {
      return Math.floor(this.remainingTime / (1000 * 60 * 60 * 24));
    },
  },
  mounted() {
    this.updateCountdown();
    setInterval(this.updateCountdown, 1000);
  },
  methods: {
    addPrefix() {
      if (this.newPrefix.length == 0) {
        this.showErrorMsgPrefix();
        return;
      }
      if (this.prefixes.length > 15) {
        this.showErrorMsgTooManyPrefixes();
        return;
      }
      if (this.prefixes.indexOf(this.newPrefix) != -1) {
        this.showErrorMsgDuplicate();
        return;
      }

      this.prefixes.push(this.newPrefix);
      this.newPrefix = "";
      this.doUpdate();
      this.showSuccessPrefix();
    },
    removePrefix(index) {
      this.prefixes.splice(index, 1);
      this.doUpdate();
      this.showSuccessPrefixRemoval();
    },
    handlenickname() {
      if (!this.nickname) {
        return this.showErrorMsgNick();
      }
      this.axios
        .patch("/api/v1/servers/" + this.$route.params.guild_id + "/nick", {
          nick: this.nickname,
        })
        .then(this.showSuccessMsg());
    },
    doUpdate() {
      this.axios.put(
        "/api/v1/servers/" + this.$route.params.guild_id + "/meta",
        {
          prefixes: this.prefixes,
          bot_channel: this.bot_channel,
        }
      );
    },
    updateCountdown() {
      const now = new Date().getTime();
      this.remainingTime = this.expiryDate - now;
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/meta")
      .then((r) => {
        this.prefixes = r.data.prefixes;
        this.is_vip = r.data.is_vip;
        this.expiryDate= new Date(r.data.expires).getTime();
        this.is_piggy = r.data.is_piggy;
        this.axios
          .get("/api/v1/servers/" + this.$route.params.guild_id + "/weblogs")
          .then((r) => {
            this.data = r.data;
            this.$Progress.finish();
          });
      });
  },
  created: function () {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/meta")
      .then((r) => {
        this.prefixes = r.data.prefixes;
        this.is_vip = r.data.is_vip;
        this.is_piggy = r.data.is_piggy;
        this.expiryDate= new Date(r.data.expires).getTime();
        this.$Progress.finish();
        this.axios
          .get("/api/v1/servers/" + this.$route.params.guild_id + "/weblogs")
          .then((r) => {
            this.data = r.data;
            this.$Progress.finish();
          });
      })
      .catch((e) => {
        this.$Progress.finish();
      });
  },
};
</script>


<style lang="scss">
#dataTable {
  width: 100%;
  margin: 0 auto;

  .VuePagination {
    text-align: center;
    justify-content: center;
  }

  .vue-title {
    text-align: center;
    margin-bottom: 10px;
  }

  .VueTables__search-field {
    display: flex;
  }
  .VueTables__search-field input {
    margin-left: 0.25rem;
  }

  .VueTables__limit-field {
    display: flex;
  }

  .VueTables__limit-field select {
    margin-left: 0.25rem !important;
  }

  .VueTables__table th {
    text-align: center;
  }

  .VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
  }

  .VueTables__child-row-toggler--closed::before {
    content: "+";
  }

  .VueTables__child-row-toggler--open::before {
    content: "-";
  }
}
</style>
<style src="spinkit/spinkit.min.css"></style>
